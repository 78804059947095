/* Fonts */
@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 300;
  src: local("ProximaNova-Light"), local("Proxima Nova Light"), url("../fonts/ProximaNova-Light.woff") format("woff"); }

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 400;
  src: local("ProximaNova-Regular"), local("Proxima Nova Regular"), url("../fonts/ProximaNova-Regular.woff") format("woff"); }

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 500;
  src: local("ProximaNova-Semibold"), local("Proxima Nova Semibold"), url("../fonts/ProximaNova-Semibold.woff") format("woff"); }

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 600;
  src: local("ProximaNova-Bold"), local("Proxima Nova Bold"), url("../fonts/ProximaNova-Bold.woff") format("woff"); }

/*  */
*,
*::before,
*::after {
  box-sizing: inherit; }

html {
  min-width: 1330px;
  box-sizing: border-box;
  background-color: white; }

body {
  position: relative;
  margin: 0;
  font-family: "ProximaNova", "Arial", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  color: #2F3441;
  background-color: #e5e5e5; }

ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

a {
  text-decoration: none; }

img {
  max-width: 100%;
  height: auto; }

.container {
  width: 1300px;
  padding: 0 15px;
  margin: 0 auto; }

.visually-hidden:not(:focus):not(:active),
input[type="checkbox"].visually-hidden,
input[type="radio"].visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  -webkit-clip-path: inset(100%);
          clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden; }

.button-blue {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 456px;
  height: 60px;
  font-size: 21px;
  line-height: 0;
  color: #ffffff;
  background-color: #0085ef;
  border-radius: 3px;
  transition: 0.2s; }
  .button-blue:hover {
    background-color: #0276D2; }

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 26px;
  border-bottom: 1px solid #D8D8D8;
  background-color: #ffffff; }

.header__logo {
  padding-left: 18px; }

.header__signin {
  line-height: 0; }

.hero {
  background: url("../img/hero-bg.svg") no-repeat center;
  background-size: cover;
  color: #ffffff; }

.hero__wrapper {
  padding: 0 32px 0 60px; }

.hero__header {
  display: flex;
  align-items: flex-end;
  padding-top: 29px; }

.hero__header-phone {
  margin: 0;
  margin-left: auto;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 29px;
  text-align: right;
  letter-spacing: 0.27px; }
  .hero__header-phone span {
    display: block;
    font-size: 13px;
    line-height: 16px; }

.hero__header-phone__link {
  color: #ffffff; }

.hero__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 54px 0 193px 0;
  position: relative; }

.hero__title {
  width: 840px;
  margin: 0;
  font-size: 60px;
  line-height: 69px;
  text-align: center;
  color: #ffffff; }

.hero__desc {
  margin: 0;
  margin-top: 18px;
  font-size: 30px;
  line-height: 39px;
  text-align: center; }
  .hero__desc span {
    display: block; }

.hero__list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  margin-top: 44px; }

.hero__list-item {
  display: flex;
  justify-content: center; }

.hero__list-text {
  position: relative;
  width: 300px;
  margin: 0;
  padding-top: 60px;
  font-size: 20px;
  line-height: 30px;
  text-align: center; }
  .hero__list-text::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 52px;
    height: 54px;
    background: url("../img/icon/icon-docs.svg") no-repeat center; }
  .hero__list-text--court::before {
    background-image: url("../img/icon/icon-court.svg"); }
  .hero__list-text--small {
    width: 230px; }
    .hero__list-text--small::before {
      width: 83px;
      background-image: url("../img/icon/icon-penalty.svg"); }

.hero__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 494px;
  height: 70px;
  margin-top: 30px;
  padding: 0;
  font-family: inherit;
  font-size: 27px;
  line-height: 33px;
  color: #ffffff;
  background-color: #0085ef;
  border: none;
  border-radius: 3px;
  transition: 0.2s; }
  .hero__button:hover {
    background-color: #0276d2; }

.about {
  position: relative;
  top: -132px;
  margin-bottom: -132px; }

.about__list {
  display: grid;
  grid-template-columns: 300px 300px 335px;
  -moz-column-gap: 90px;
       column-gap: 90px;
  padding: 42px 32px 44px 60px;
  background: #ffffff;
  border: 1px solid #E6E6E6;
  border-radius: 3px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05); }

.about__item-text {
  position: relative;
  margin: 0;
  padding-top: 89px;
  font-size: 20px;
  line-height: 30px; }
  .about__item-text::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 109px;
    height: 70px;
    background: url("../img/icon/icon-price.svg") no-repeat center; }
  .about__item-text--shield::before {
    width: 60px;
    background-image: url("../img/icon/icon-shield.svg"); }
  .about__item-text--doc::before {
    width: 51px;
    background-image: url("../img/icon/icon-docs-rose.svg"); }

.support {
  margin-top: 60px; }

.support__title {
  margin: 0;
  font-size: 45px;
  line-height: 46px;
  text-align: center; }

.support__content {
  padding: 39px 71px 74px;
  border: 1px solid #E6E6E6;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  background-color: #ffffff; }
  .support__content:first-of-type {
    margin-top: 65px; }

.support__content-title {
  margin: 0;
  font-size: 35px;
  line-height: 46px;
  font-weight: 300;
  text-align: center; }

.support__chat-list {
  margin-top: 40px; }

.chat-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 49px; }
  .chat-item:last-child {
    margin-bottom: 0; }

.chat-item__user-pic {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #E6E6E6; }

.chat-item__user-text {
  width: 967px;
  margin: 0;
  padding: 39px 34px 28px 21px;
  font-size: 22px;
  line-height: 36px;
  font-weight: 600;
  border: 1px solid #E6E6E6;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05);
  border-radius: 3px 3px 3px 0px; }
  .chat-item__user-text--answer {
    padding: 31px 19px 29px 23px;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    background-color: #F6F6FA;
    border: none; }

.support__inset {
  padding: 66px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center; }

.support__inset-title {
  width: 830px;
  margin: 0 auto;
  font-size: 30px;
  font-weight: 400;
  line-height: 40px; }

.support__inset-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 353px;
  height: 60px;
  margin: 40px auto 0;
  font-size: 21px;
  line-height: 26px;
  color: #ffffff;
  background-color: #0085ef;
  border-radius: 3px;
  transition: 0.2s; }
  .support__inset-link:hover {
    background-color: #0276d2; }

.preorder {
  margin-top: 78px; }

.preorder__wrapper {
  width: 1150px;
  margin: 0 auto; }

.preorder__title {
  width: 996px;
  margin: 0 auto;
  font-size: 33px;
  font-weight: 300;
  line-height: 40px;
  text-align: center; }
  .preorder__title span {
    display: block; }

.preorder__desc {
  font-size: 18px;
  line-height: 1.44;
  text-align: center;
  padding-bottom: 23px; }
  .preorder__desc span {
    display: block; }

.preorder__form {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-top: 58px;
  margin-left: 0; }

.preorder__form-label {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  text-transform: uppercase;
  width: 277px;
  margin-right: 16px;
  margin-bottom: 0; }

.preorder__form-input {
  height: 46px;
  margin-top: 6px;
  padding-left: 20px;
  font-size: 16px;
  border: 1px solid #e6e6e6;
  border-radius: 3px; }

.preorder__button {
  width: 260px;
  height: 46px;
  margin-left: auto;
  padding: 0;
  font-family: inherit;
  font-size: 18px;
  color: #fff;
  background-color: #0085ef;
  border: none;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  transition: 0.2s; }
  .preorder__button:hover {
    background-color: #0276d2; }

.preorder__form-permission {
  width: 230px;
  margin-left: auto;
  font-size: 12px;
  line-height: 18px;
  text-align: right; }

.footer__wrapper {
  display: flex;
  align-items: flex-end;
  width: 1150px;
  margin: 0 auto;
  padding: 56px 0 80px 0;
  line-height: 16px; }

.footer__copyright {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  font-size: 13px; }

.footer__policy {
  margin-top: 20px;
  text-decoration: underline;
  color: #2F3441; }

.footer__callback {
  margin: 0;
  margin-left: 185px;
  font-size: 13px; }

.footer__phone {
  display: block;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: #2F3441; }

.footer__logo {
  margin-left: auto; }
