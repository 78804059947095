
.hero {
  background: url('../img/hero-bg.svg') no-repeat center;
  background-size: cover;
  color: $white; }

.hero__wrapper {
  padding: 0 32px 0 60px; }

.hero__header {
  display: flex;
  align-items: flex-end;
  padding-top: 29px; }

.hero__header-phone {
  margin: 0;
  margin-left: auto;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 29px;
  text-align: right;
  letter-spacing: 0.27px;

  span {
    display: block;
    font-size: 13px;
    line-height: 16px; } }

.hero__header-phone__link {
  color: $white; }

.hero__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 54px 0 193px 0;
  position: relative; }

.hero__title {
  width: 840px;
  margin: 0;
  font-size: 60px;
  line-height: 69px;
  text-align: center;
  color: $white; }

.hero__desc {
  margin: 0;
  margin-top: 18px;
  font-size: 30px;
  line-height: 39px;
  text-align: center;

  span {
    display: block; } }

.hero__list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  margin-top: 44px; }

.hero__list-item {
  display: flex;
  justify-content: center; }

.hero__list-text {
  position: relative;
  width: 300px;
  margin: 0;
  padding-top: 60px;
  font-size: 20px;
  line-height: 30px;
  text-align: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 52px;
    height: 54px;
    background: url('../img/icon/icon-docs.svg') no-repeat center; }

  &--court {
    &::before {
      background-image: url('../img/icon/icon-court.svg'); } }


  &--small {
    width: 230px;
    &::before {
      width: 83px;
      background-image: url('../img/icon/icon-penalty.svg'); } } }

.hero__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 494px;
  height: 70px;
  margin-top: 30px;
  padding: 0;
  font-family: inherit;
  font-size: 27px;
  line-height: 33px;
  color: $white;
  background-color: $basic-blue;
  border: none;
  border-radius: 3px;
  transition: 0.2s;

  &:hover {
      background-color: #0276d2; } }
