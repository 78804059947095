.support {
  margin-top: 60px; }

.support__title {
  margin: 0;
  font-size: 45px;
  line-height: 46px;
  text-align: center; }

.support__content {
  padding: 39px 71px 74px;
  border: 1px solid #E6E6E6;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  background-color: $white;

  &:first-of-type {
    margin-top: 65px; } }

.support__content-title {
  margin: 0;
  font-size: 35px;
  line-height: 46px;
  font-weight: 300;
  text-align: center; }

.support__chat-list {
  margin-top: 40px; }

.chat-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 49px;

  &:last-child {
    margin-bottom: 0; } }

.chat-item__user-pic {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #E6E6E6; }

.chat-item__user-text {
  width: 967px;
  margin: 0;
  padding: 39px 34px 28px 21px;
  font-size: 22px;
  line-height: 36px;
  font-weight: 600;
  border: 1px solid #E6E6E6;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05);
  border-radius: 3px 3px 3px 0px;

  &--answer {
    padding: 31px 19px 29px 23px;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    background-color: #F6F6FA;
    border: none; } }


.support__inset {
  padding: 66px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center; }

.support__inset-title {
  width: 830px;
  margin: 0 auto;
  font-size: 30px;
  font-weight: 400;
  line-height: 40px; }

.support__inset-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 353px;
  height: 60px;
  margin: 40px auto 0;
  font-size: 21px;
  line-height: 26px;
  color: $white;
  background-color: $basic-blue;
  border-radius: 3px;
  transition: 0.2s;

  &:hover {
      background-color: #0276d2; } }
