.about {
  position: relative;
  top: -132px;
  margin-bottom: -132px; }

.about__list {
  display: grid;
  grid-template-columns: 300px 300px 335px;
  column-gap: 90px;
  padding: 42px 32px 44px 60px;
  background: $white;
  border: 1px solid #E6E6E6;
  border-radius: 3px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05); }

.about__item-text {
  position: relative;
  margin: 0;
  padding-top: 89px;
  font-size: 20px;
  line-height: 30px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 109px;
    height: 70px;
    background: url('../img/icon/icon-price.svg') no-repeat center; }

  &--shield {
    &::before {
      width: 60px;
      background-image: url('../img/icon/icon-shield.svg'); } }


  &--doc {
    &::before {
      width: 51px;
      background-image: url('../img/icon/icon-docs-rose.svg'); } } }
