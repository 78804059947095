/* Fonts */
@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 300;
  src: local('ProximaNova-Light'), local('Proxima Nova Light'), url('../fonts/ProximaNova-Light.woff') format('woff'); }

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 400;
  src: local('ProximaNova-Regular'), local('Proxima Nova Regular'), url('../fonts/ProximaNova-Regular.woff') format('woff'); }

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 500;
  src: local('ProximaNova-Semibold'), local('Proxima Nova Semibold'), url('../fonts/ProximaNova-Semibold.woff') format('woff'); }

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 600;
  src: local('ProximaNova-Bold'), local('Proxima Nova Bold'), url('../fonts/ProximaNova-Bold.woff') format('woff'); }
/*  */
