.footer__wrapper {
  display: flex;
  align-items: flex-end;
  width: 1150px;
  margin: 0 auto;
  padding: 56px 0 80px 0;
  line-height: 16px; }

.footer__copyright {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  font-size: 13px; }

.footer__policy {
  margin-top: 20px;
  text-decoration: underline;
  color: $basic-gray; }

.footer__callback {
  margin: 0;
  margin-left: 185px;
  font-size: 13px; }

.footer__phone {
  display: block;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: $basic-gray; }

.footer__logo {
  margin-left: auto; }
