
$white: #ffffff;
$basic-gray: #2F3441;
$basic-blue: #0085ef;

*,
*::before,
*::after {
  box-sizing: inherit; }

html {
  min-width: 1330px;
  box-sizing: border-box;
  background-color: white; }

body {
  position: relative;
  margin: 0;
  font-family: "ProximaNova", "Arial", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  color: $basic-gray;
  background-color: #e5e5e5; }

ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

a {
  text-decoration: none; }

img {
  max-width: 100%;
  height: auto; }

.container {
  width: 1300px;
  padding: 0 15px;
  margin: 0 auto; }

.visually-hidden:not(:focus):not(:active),
input[type="checkbox"].visually-hidden,
input[type="radio"].visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden; }

.button-blue {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 456px;
  height: 60px;
  font-size: 21px;
  line-height: 0;
  color: $white;
  background-color: $basic-blue;
  border-radius: 3px;

  transition: 0.2s;

  &:hover {
    background-color:#0276D2 {} } }
