.preorder {
    margin-top: 78px; }

.preorder__wrapper {
    width: 1150px;
    margin: 0 auto; }

.preorder__title {
    width: 996px;
    margin: 0 auto;
    font-size: 33px;
    font-weight: 300;
    line-height: 40px;
    text-align: center;

    span {
        display: block; } }

.preorder__desc {
    font-size: 18px;
    line-height: 1.44;
    text-align: center;
    padding-bottom: 23px;
    span {
        display: block; } }

.preorder__form {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin-top: 58px;
    margin-left: 0; }

.preorder__form-label {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    text-transform: uppercase;
    width: 277px;
    margin-right: 16px;
    margin-bottom: 0; }

.preorder__form-input {
    height: 46px;
    margin-top: 6px;
    padding-left: 20px;
    font-size: 16px;
    border: 1px solid #e6e6e6;
    border-radius: 3px; }

.preorder__button {
    width: 260px;
    height: 46px;
    margin-left: auto;
    padding: 0;
    font-family: inherit;
    font-size: 18px;
    color: #fff;
    background-color: $basic-blue;
    border: none;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
        background-color: #0276d2; } }

.preorder__form-permission {
    width: 230px;
    margin-left: auto;
    font-size: 12px;
    line-height: 18px;
    text-align: right; }
